import React from "react";
import { Button, Input } from "components/common";
import { Error, Center, InputField } from "./styles";
import { useForm, ValidationError } from "@formspree/react";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xjvjygoz");
  if (state.succeeded) {
    return (
      <InputField>
        <Center>
          <h4>
            Your message has been successfully sent, I will get back to you
            ASAP!
          </h4>
        </Center>
      </InputField>
    );
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputField>
          <Input
            type="text"
            name="name"
            component="input"
            aria-label="name"
            placeholder="Full name*"
          />
          <ValidationError
            component={Error}
            prefix="name"
            field="text"
            errors={state.errors}
            name="name"
          />
        </InputField>
        <InputField>
          <Input
            id="email"
            aria-label="email"
            component="input"
            type="email"
            name="email"
            placeholder="Email*"
          />
          <ValidationError
            component={Error}
            prefix="Email"
            field="emaiil"
            errors={state.errors}
            name="email"
          />
        </InputField>
        <InputField>
          <Input
            component="textarea"
            aria-label="message"
            id="message"
            rows="8"
            name="message"
            placeholder="Message*"
          />
          <ValidationError
            component={Error}
            prefix="Message"
            field="message"
            errors={state.errors}
            name="message"
          />
        </InputField>
        <Center>
          <Button secondary type="submit" disabled={state.submitting}>
            Submit
          </Button>
        </Center>
      </form>
    </>
  );
};

export default ContactForm;
